/* FUENTES */
@font-face {
  font-family: "LatoBold";
  src: url("./assets/fonts/fontBold.woff");
  font-weight: normal;
  font-style: normal;
}
.font-latoBold {
  font-family: LatoBold !important;
  font-weight: normal;
}

@font-face {
  font-family: "LatoBlack";
  src: url("./assets/fonts/fontBlack.woff");
  font-weight: normal;
  font-style: normal;
}
.font-latoBlack {
  font-family: LatoBlack !important;
  font-weight: normal;
}

@font-face {
  font-family: "LatoItalic";
  src: url("./assets/fonts/fontItalic.woff");
  font-weight: normal;
  font-style: normal;
}
.font-latoItalic {
  font-family: LatoItalic !important;
  font-weight: normal;
}

@font-face {
  font-family: "LatoRegular";
  src: url("./assets/fonts/fontRegular.woff");
  font-weight: normal;
  font-style: normal;
}
.font-latoRegular {
  font-family: LatoRegular !important;
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MontserratBold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

.hline1 {
  border-style: inset;
  border-width: 1px;
  color: #233348;
  background-color: #233348;
  opacity: 0.4;
}

.hline2 {
  border-style: inset;
  border-width: 1px;
  color: #233348;
  background-color: #233348;
  opacity: 0.6;
}

body {
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: auto;
}

/* **************************************** */
.color-azul-oscuro {
  color: #263c6e;
}

.bgcolor-azul-oscuro {
  background-color: #263c6e;
}

.from-cyan-500 {
  --tw-gradient-from: #2f3267;
  --tw-gradient-to: #4247a4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.color-azul-claro {
  color: #2f3267;
}

.casi-negro {
  color: #4e4a5c;
}
.bgcolor-azul-claro {
  background-color: #263c6e;
}
.border-azul-claro {
  border-color: #263c6e;
}

.fondo-blanco-gris {
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  display: none;
}
